import React, { useState } from "react"
import Base from "./Base"
import Content from "../particles/Content"
import RowImage from "../organisms/rowImage/RowImage"
import { css } from "@emotion/core"
import theme from "../particles/Theme"
import Breadcrumbs from "../organisms/breadcrumbs/Breadcrumbs"
import Banner from "../organisms/banner/Banner"
import MultiColumnRow from "../organisms/multiColumnRow/MultiColumnRow"
import { formatPhoneNumber, randomID, decodeHTMLEntities } from "../particles/helpers"
import { MdEmail, MdPhone } from "react-icons/md"
import { FaFax } from "react-icons/fa"
import { FaFileAlt } from "react-icons/fa"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import SubHeading from "../atoms/SubHeading/SubHeading"

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  td {
    border: 1px solid #666;
    margin: 0;
    padding: 2rem;
    width: 50%;
  }

  a {
    color: ${theme.colors.primary};
    transition: color 0.2s ease-in-out;

    &:hover {
      color: ${theme.colors.primaryHover};
    }
  }
`

const toggle = css`
  @media (max-width: ${theme.breakpoints[1]}) {
    text-align: center;
  }

  section {
    position: absolute;
    top: 2rem;
    left: 0;
    display: inline-block;

    @media (max-width: ${theme.breakpoints[1]}) {
      position: relative;
      width: 150px;
    }

    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      margin-left: 2rem;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      border-radius: 34px;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: ${theme.colors.primary};
    }

    input:focus + .slider {
      box-shadow: 0 0 1px ${theme.colors.primary};
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }
  }
`

const Page = ({ pageContext }) => {
  let {
    aboutOurBranch,
    branchFeaturedVendors,
    branchAddress,
    address,
    city,
    zipCode,
    state,
    branchDistributionAreaImage,
    faxNumber,
    phoneNumber,
    tollFreeNumber,
    contacts,
    branchImage,
    generalEmail,
    addCopyInSpanish,
    spanishAboutOurBranch,
    specReps,
    videoEmbeds,
  } = pageContext.Branch

  let { residentialProducts, commercialProducts } = pageContext.productSelection

  residentialProducts = Object.entries(residentialProducts)
  commercialProducts = Object.entries(commercialProducts)

  let deadStockList = '';
  if ( pageContext.branchSide != null ) {
    if ( pageContext.branchSide.deadStockList != null ) {
      deadStockList = pageContext.branchSide.deadStockList.link
    }
  }

  const [isSpanish, setSpanish] = useState(false)

  const disclaimer = css`
    background: ${theme.colors.primary};
    color: white;
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto ${theme.spacing.marginDefault};

    p {
      margin: 0;
      padding: 3rem 5rem;

      @media (max-width: ${theme.breakpoints[0]}) {
        padding: 3rem 0;
      }
    }

    @media (max-width: ${theme.breakpoints[1]}) {
      margin: 0 auto;
    }
  `

  const columns = []

  if ( ! branchFeaturedVendors ) {
    branchFeaturedVendors = [];
  }

  branchFeaturedVendors.forEach(branchVendor => {
    let column = {
      text_area: ``,
      icon: branchVendor.VendorInfo.logo,
    }
    columns.push(column)
  })

  const multiColumnRow = {
    title: isSpanish ? `Proveedores destacados` : `Featured Vendors`,
    columns: columns,
  }

  const SpecRepTable = styled.table`
    margin-bottom: 2rem;

    @media (max-width: ${theme.breakpoints[1]}) {
      width: 100%;
      text-align: center;

      td {
        width: 50%;
      }
    }

    a {
      color: ${theme.colors.primary};

      &:hover {
        color: ${theme.colors.primaryHover};
      }
    }

    th {
      text-align: left;
      @media (max-width: ${theme.breakpoints[1]}) {
        text-align: center;
      }
    }

    td:nth-of-type(2) {
      border-left: 1px solid ${theme.colors.secondary};
      padding-left: 2rem;
    }
  `

  const ProductUL = styled.ul`
    padding-top: 2rem;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;

    li {
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
    }

    @media (max-width: ${theme.breakpoints[0]}) {
      columns: 1;
      -webkit-columns: 1;
      -moz-columns: 1;
    }
  `

  if (videoEmbeds) {
    videoEmbeds.forEach(video => {
      let videoCode = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${video.videoCode}" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`

      aboutOurBranch = videoCode + `<br />` + aboutOurBranch
      spanishAboutOurBranch = videoCode + `<br />` + aboutOurBranch
    })
  }

  const RowImageBlock = {
    rowImageTitle: isSpanish ? `Contactos` : `Contact Us`,
    rowImageField: branchImage,
    style: css`paddingBottom: 0`,
    imagePlacement: true,
    small: true
  }

  const RowImageBlock2 = {
    rowImageTitle:
      isSpanish
        ? `Referente a Nuestra División de ${pageContext.title}`
        : `About Dixie Plywood and Lumber Company ${
            pageContext.title.toUpperCase() === "EXPORTING" ? "|" : `of`
          } ${pageContext.title}`,
    rowImageText: isSpanish ? spanishAboutOurBranch : aboutOurBranch,
    rowImageField: branchDistributionAreaImage,
    small: true
  }

  return (
    <Base context={pageContext} title={pageContext.title}>
      <Banner
        title={
          pageContext.bannerTitle ? pageContext.bannerTitle : pageContext.title
        }
      />
      <Breadcrumbs />
      {addCopyInSpanish ? (
        <Content css={toggle}>
          <section>
            {isSpanish ? `View in English` : `Ver en Español`}
            <label className="switch">
              <input
                type="checkbox"
                onClick={() => setSpanish(!isSpanish)}
                checked={isSpanish || undefined}
              />
              <span className="slider"></span>
            </label>
          </section>
        </Content>
      ) : null}
      {branchImage ? (
        <RowImage RowImageBlock={RowImageBlock}>
          <Table>
            <tbody>
              {contacts.map((contact, idx) =>
                idx % 2 ? null : contacts.length - idx !== 1 ? (
                  <tr key={randomID()}>
                    <td>
                      <Link
                        to="/contact-us"
                        state={{
                          branch: pageContext.title,
                          contact: `${contact.name} - ${contact.title}`,
                          email: contact.email,
                        }}
                      >
                        <strong>{contact.name}</strong>
                        <br />
                        {isSpanish ? contact.titleInSpanish : contact.title}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to="/contact-us"
                        state={{
                          branch: pageContext.title,
                          contact: `${contacts[idx + 1].name} - ${
                            contacts[idx + 1].title
                          }`,
                          email: contact.email,
                        }}
                      >
                        <strong>{contacts[idx + 1].name}</strong>
                        <br />
                        {isSpanish
                          ? contacts[idx + 1].titleInSpanish
                          : contacts[idx + 1].title}
                      </Link>
                    </td>
                  </tr>
                ) : (
                  <tr key={randomID()}>
                    <td>
                      <Link
                        to="/contact-us"
                        state={{
                          branch: pageContext.title,
                          contact: `${contact.name} - ${contact.title}`,
                          email: contact.email,
                        }}
                      >
                        <strong>{contact.name}</strong>
                        <br />
                        {isSpanish ? contact.titleInSpanish : contact.title}
                      </Link>
                    </td>
                    <td></td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
          <h4 style={{ fontWeight: "400" }}>
            {branchAddress.streetNumber} {branchAddress.streetName}
            <br />
            {branchAddress.city}, {branchAddress.stateShort},{" "}
            {branchAddress.postCode}
            {address ? (
              <>
                <br />
                <br />
                <span style={{fontSize: `2.6rem`, fontWeight: `900` }}>Shipping Address:</span><br />
                {address}
                <br />
                {city}, {state}, {zipCode}
              </>
            ) : null}
          </h4>
          <h4>
            <MdPhone /> {isSpanish ? `Teléfono: ` : `PHONE: `}
            <a
              href={`tel: ${formatPhoneNumber(phoneNumber)}`}
              style={{ color: theme.colors.bodyFont }}
            >
              {formatPhoneNumber(phoneNumber)}
            </a>
            <br />
            {tollFreeNumber ? (
              <>
                <MdPhone /> {isSpanish ? `Teléfono: ` : `TOLL FREE: `}
                <a
                  href={`tel: ${formatPhoneNumber(tollFreeNumber)}`}
                  style={{ color: theme.colors.bodyFont }}
                >
                  {formatPhoneNumber(tollFreeNumber)}
                </a>
                <br />
              </>
            ) : null}
            <FaFax /> Fax: {formatPhoneNumber(faxNumber)}
            <br />
            <MdEmail /> {isSpanish ? `Correo Electrónico: ` : `Email: `}
            <Link
              to="/contact-us"
              state={{
                branch: pageContext.title,
                contact: `general`,
                email: generalEmail,
              }}
              css={css`
                color: ${theme.colors.primary};
                transition: color 0.2s ease-in-out;
                &:hover {
                  color: ${theme.colors.primaryHover};
                }
              `}
            >
              {generalEmail}
            </Link>
          </h4>
          {specReps ? (
            <SpecRepTable>
              <tbody>
                <tr>
                  <th colSpan="2">
                    <h4>Specifications Representatives</h4>
                  </th>
                </tr>
                {specReps.map((rep, idx) => {
                  const length = specReps.length

                  const rowContent = rep => (
                    <article key={randomID()}>
                      <Link
                        to="/contact-us"
                        state={{
                          branch: pageContext.title,
                          contact: `${rep.name} - Specification Representative`,
                          email: rep.email,
                        }}
                      >
                        <strong>{rep.name}</strong>
                      </Link>
                      <br />
                      {rep.phone}
                    </article>
                  )

                  const rows = () => {
                    if (length - idx > 2 && idx % 2 === 0) {
                      return (
                        <>
                          <td>{rowContent(rep)}</td>
                          <td>{rowContent(specReps[idx + 1])}</td>
                        </>
                      )
                    } else if (length - idx === 2) {
                      return (
                        <>
                          <td>{rowContent(rep)}</td>
                          <td>{rowContent(specReps[idx + 1])}</td>
                        </>
                      )
                    } else if (length - idx === 1 && length < 2) {
                      return <td>{rowContent(rep)}</td>
                    } else {
                      return null
                    }
                  }

                  return <tr key={randomID()}>{rows()}</tr>
                })}
              </tbody>
            </SpecRepTable>
          ) : null}

          { deadStockList ? (
            <h4>
              <strong>Clearance Items: </strong>
              <a href={deadStockList} target="_blank" rel="noreferrer"><FaFileAlt /> Download</a>
            </h4>
          ) : null }
        </RowImage>
      ) : (
        <h4>Error: Missing image</h4>
      )}

      <Content css={disclaimer}>
        <p>
          {isSpanish
            ? `TENGA EN CUENTA: SOMOS DISTRIBUIDORES AL POR MAYOR SOLAMENTE Y POR TANTO, NO VENDEMOS A CONTRATISTAS O AL PÚBLICO GENERAL. SIN EMBARGO, SI TIENE PROBLEMAS DE UN PRODUCTO QUE DISTRIBUIMOS, PODEMOS SUGERIR A UNO DE NUESTROS DISTRIBUIDORES / DISTRIBUIDORES PARA QUE CONTACTENOS.`
            : `Please note: We are wholesale distributors only and as such, we do not
          sell to contractors or the general public. However, if you are having
          trouble sourcing a product we distribute, we can suggest one of our
          retailer/dealers for you to contact.`}
        </p>
      </Content>
      {branchDistributionAreaImage ? (
        <RowImage RowImageBlock={RowImageBlock2}/>
      ) : (
        <h4>Error: Missing image</h4>
      )}
      {branchFeaturedVendors.length > 0 ? (
        <MultiColumnRow MultiColumnRow={multiColumnRow} image />
      ): (<p></p>)}
      <Content style={{ margin: `5rem auto` }}>
        <h3 style={{ textAlign: `center` }}>
          {isSpanish ? `Nuestros productos` : `Our Products`}
        </h3>
        <SubHeading title="Residential" style={{ marginTop: `5rem` }} />
        <ProductUL>
          {residentialProducts.map(resProduct => {
            let fields = Object.entries(resProduct[1])

            // Really long if statement, but we need to check if the category has vendors, products, or subcategories before showing them.
            if (
              (resProduct[1].vendors !== null &&
                resProduct[1].vendors !== undefined &&
                resProduct[1].vendors.length > 0) ||
              (resProduct[1].products !== null &&
                resProduct[1].products !== undefined &&
                resProduct[1].products.length > 0) ||
              (resProduct[1].subcat1.subcatname &&
                ((resProduct[1].subcat1.vendors !== null &&
                  resProduct[1].subcat1.vendors !== undefined &&
                  resProduct[1].subcat1.vendors.length > 0) ||
                  (resProduct[1].subcat1.products !== null &&
                    resProduct[1].subcat1.products !== undefined &&
                    resProduct[1].subcat1.products.length > 0) ||
                  resProduct[1].subcat1.subcat1.subcatname ||
                  resProduct[1].subcat2.subcat1.subcatname ||
                  resProduct[1].subcat3.subcat1.subcatname ||
                  resProduct[1].subcat4.subcat1.subcatname ||
                  resProduct[1].subcat5.subcat1.subcatname))
            ) {
              return (
                <li key={randomID()}>
                  {decodeHTMLEntities(resProduct[1].catname)}
                  <ul>
                    {resProduct[1].vendors &&
                    Array.isArray(resProduct[1].vendors) ? (
                      <>
                        {resProduct[1].vendors.map(vendor => (
                          <li key={randomID()}>
                            {decodeHTMLEntities(vendor.replace(/_/g, " "))}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {resProduct[1].products &&
                    Array.isArray(resProduct[1].products) ? (
                      <>
                        {resProduct[1].products.map(product => (
                          <li key={randomID()}>
                            {decodeHTMLEntities(product.replace(/_/g, " "))}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {fields.map(field => {
                      // This is to keep errors from popping up. We can only run Object.entries on objects. Also, want to make sure these are actual fields and not dummy fields.
                      if (
                        typeof field[1] !== "object" ||
                        field[1] === null ||
                        field[1].subcatname === null
                      ) {
                        return null
                      }

                      // Make sure a subcategory has products, vendors, or subcategories before we show them.
                      if (
                        field[1].products === null &&
                        field[1].vendors === null &&
                        field[1].subcat1.subcatname === null
                      ) {
                        return null
                      }
                      let subFields = Object.entries(field[1])

                      return (
                        <article key={randomID()}>
                          {field[0] !== "subcatname" &&
                          field[0] !== "products" &&
                          field[0] !== "vendors" ? (
                            <li>
                              {decodeHTMLEntities(field[1].subcatname)}
                              <ul>
                                {field[1].vendors &&
                                Array.isArray(field[1].vendors) ? (
                                  <>
                                    {field[1].vendors.map(vendor => (
                                      <li key={randomID()}>
                                        {decodeHTMLEntities(
                                          vendor.replace(/_/g, " ")
                                        )}
                                      </li>
                                    ))}
                                  </>
                                ) : null}
                                {field[1].products &&
                                Array.isArray(field[1].products) ? (
                                  <>
                                    {field[1].products.map(product => (
                                      <li key={randomID()}>
                                        {decodeHTMLEntities(
                                          product.replace(/_/g, " ")
                                        )}
                                      </li>
                                    ))}
                                  </>
                                ) : null}
                                {subFields.map(subField => {
                                  return (
                                    <article key={randomID()}>
                                      {subField[0] !== "subcatname" &&
                                      subField[0] !== "products" &&
                                      subField[0] !== "vendors" &&
                                      subField[1].subcatname ? (
                                        <li>
                                          {subField[1].subcatname}
                                          <ul>
                                            {subField[1].vendors &&
                                            Array.isArray(
                                              subField[1].vendors
                                            ) ? (
                                              <>
                                                {subField[1].vendors.map(
                                                  vendor => (
                                                    <li key={randomID()}>
                                                      {decodeHTMLEntities(
                                                        vendor.replace(
                                                          /_/g,
                                                          " "
                                                        )
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                            {subField[1].products &&
                                            Array.isArray(
                                              subField[1].products
                                            ) ? (
                                              <>
                                                {subField[1].products.map(
                                                  product => (
                                                    <li key={randomID()}>
                                                      {decodeHTMLEntities(
                                                        product.replace(
                                                          /_/g,
                                                          " "
                                                        )
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                          </ul>
                                        </li>
                                      ) : null}
                                    </article>
                                  )
                                })}
                              </ul>
                            </li>
                          ) : null}
                        </article>
                      )
                    })}
                  </ul>
                </li>
              )
            } else {
              return null
            }
          })}
        </ProductUL>
        <SubHeading title="Commercial" style={{ marginTop: `5rem` }} />
        <ProductUL>
          {commercialProducts.map(comProduct => {
            let fields = Object.entries(comProduct[1])

            if (
              (comProduct[1].vendors !== null &&
                comProduct[1].vendors !== undefined &&
                comProduct[1].vendors.length > 0) ||
              (comProduct[1].products !== null &&
                comProduct[1].products !== undefined &&
                comProduct[1].products.length > 0) ||
              (comProduct[1].subcat1.subcatname &&
                comProduct[1].subcat1.products !== null &&
                comProduct[1].subcat1.vendors !== null &&
                comProduct[1].subcat1.subcat1.subcatname)
            ) {
              return (
                <li key={randomID()}>
                  {decodeHTMLEntities(comProduct[1].catname)}
                  <ul>
                    {comProduct[1].vendors &&
                    Array.isArray(comProduct[1].vendors) ? (
                      <>
                        {comProduct[1].vendors.map(vendor => (
                          <li key={randomID()}>
                            {decodeHTMLEntities(vendor.replace(/_/g, " "))}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {comProduct[1].products &&
                    Array.isArray(comProduct[1].products) ? (
                      <>
                        {comProduct[1].products.map(product => (
                          <li key={randomID()}>
                            {decodeHTMLEntities(product.replace(/_/g, " "))}
                          </li>
                        ))}
                      </>
                    ) : null}
                    {fields.map(field => {
                      // This is to keep errors from popping up. We can only run Object.entries on objects. Also, want to make sure these are actual fields and not dummy fields.
                      if (
                        typeof field[1] !== "object" ||
                        field[1] === null ||
                        field[1].subcatname === null
                      ) {
                        return null
                      }

                      // Make sure a subcategory has products, vendors, or subcategories before we show them.
                      if (
                        field[1].products === null &&
                        field[1].vendors === null &&
                        field[1].subcat1.subcatname === null
                      ) {
                        return null
                      }

                      let subFields = Object.entries(field[1])

                      return (
                        <article key={randomID()}>
                          {field[0] !== "subcatname" &&
                          field[0] !== "products" &&
                          field[0] !== "vendors" ? (
                            <li>
                              {decodeHTMLEntities(field[1].subcatname)}
                              <ul>
                                {field[1].vendors &&
                                Array.isArray(field[1].vendors) ? (
                                  <>
                                    {field[1].vendors.map(vendor => (
                                      <li key={randomID()}>
                                        {decodeHTMLEntities(
                                          vendor.replace(/_/g, " ")
                                        )}
                                      </li>
                                    ))}
                                  </>
                                ) : null}
                                {field[1].products &&
                                Array.isArray(field[1].products) ? (
                                  <>
                                    {field[1].products.map(product => (
                                      <li key={randomID()}>
                                        {decodeHTMLEntities(
                                          product.replace(/_/g, " ")
                                        )}
                                      </li>
                                    ))}
                                  </>
                                ) : null}
                                {subFields.map(subField => {
                                  return (
                                    <article key={randomID()}>
                                      {subField[0] !== "subcatname" &&
                                      subField[0] !== "products" &&
                                      subField[0] !== "vendors" &&
                                      subField[0] !== "subcat1" &&
                                      subField[0] !== "subcat2" &&
                                      subField[0] !== "subcat3" ? (
                                        <li>
                                          {subField[1].subcatname}
                                          <ul>
                                            {subField[1].vendors ? (
                                              <>
                                                {subField[1].vendors.map(
                                                  vendor => (
                                                    <li key={randomID()}>
                                                      {decodeHTMLEntities(
                                                        vendor.replace(
                                                          /_/g,
                                                          " "
                                                        )
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                            {subField[1].products ? (
                                              <>
                                                {subField[1].products.map(
                                                  product => (
                                                    <li key={randomID()}>
                                                      {decodeHTMLEntities(
                                                        product.replace(
                                                          /_/g,
                                                          " "
                                                        )
                                                      )}
                                                    </li>
                                                  )
                                                )}
                                              </>
                                            ) : null}
                                          </ul>
                                        </li>
                                      ) : null}
                                    </article>
                                  )
                                })}
                              </ul>
                            </li>
                          ) : null}
                        </article>
                      )
                    })}
                  </ul>
                </li>
              )
            }
            return null
          })}
        </ProductUL>

      </Content>
    </Base>
  )
}

export default Page
